:root {
  --header-bg_color: #55057D;
  --header-color:#fff;
  --header-color_hover:  #2572EF;
  --balance-heading:#2572EF;
  --balance-value:#f4f8fb;
  --profile-dropdown_color: #000;
  --profile-dropdown_color_hover: #2572EF;
  --profile-dropdown_bg_hover: #FFF6DA;
  --login-btn-color:#fff;
  --login-btn-bg:#2572EF;
  --heading_2-color: #001A16;
  --logoName-bgColor:linear-gradient(to right, #cb3066, #2572EF);

  --tab-link-color: #fff;
  --tab-link-color-hover: #141414;
  --tab-link-bgColor: rgba(255, 255, 255, 0.06);
  --tab-link-bgColor-hover: rgba(255, 255, 255, 1);

  --tab-content-bgColor: #ffedb2;
  --tab-content-boxShadow: 0 1px 1px #ffedb2;
  --tab-content-left-bgColor: #F4F8F8;
  --tab-content-input-color:#141414;
  --tab-content-input-border:1px solid rgba(0, 0, 0, 0.15);
  --tab-content-border: 1px solid #e5e5e5;

  --footer-border-color: #5c5b5b;
  --footer-border-link-color:#fff;
  --footer-contact-h2-color:#363636;
  --footer-img-shadow: 0px 1px 2px #00000036;
  --footer-socile-icon-bgColor: #5c5b5b;
  --footer-socile-icon-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(255, 255, 255, 0.1) 0px 0px 1px 0px;
  --footer-contact-img-bgColor: #FFF6DA;
  

  --GrowYourBussionWrapper-bgColor: #fff;
  --GrowYourBussion-wrapper-img-bgColor:#650D91;

  --slick-active-color:#2572EF;
  --slick-dots-color: #fff;

  --section-gbColor-V1: #c174e71a;
  --section-gbColor-V2: #dfdfdf;

  --blue: #1e90ff;
  --white: #ffffff;
}

